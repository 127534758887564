:root { 
  --bdazzled-blue: #475993;
  --black: #000000;
  --e30746: #e30746;
  --gunsmoke: #848484;
  --oslo-gray: #8a898e;
  --quill-gray: #d3d3d3;
  --shamrock: #34e0a1;
  --shark: #272727;
  --white: #ffffff;
 
  --font-size-m: 18px;
  --font-size-s: 16px;
  --font-size-xl2: 36px;
  --font-size-xs: 15px;
  --font-size-xs2: 12px;
  --font-size-xxl: 52px;
  --font-size-xxs: 14px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
}

.montserrat-bold-shark-36px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-shark-15px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.montserrat-normal-shark-18px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 600;
}
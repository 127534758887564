/* screen - login */

.login {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 606.89px;
  overflow: hidden;
  width: 1440px;
}

.login .overlap-group {
  height: 607px;
  margin-top: 0;
  position: relative;
  width: 1440px;
}

.login .rectangle-13 {
  background-color: var(--white);
  height: 136px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.login .image-34 {
  height: 607px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1440px;
}

.login .frame-13 {
  align-items: center;
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  flex-direction: column;
  left: 515px;
  min-height: 358px;
  padding: 44px 0;
  position: absolute;
  top: 124px;
  width: 410px;
}

.login .frame-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 210px;
  width: 336px;
}

.login .frame-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  width: 336px;
}

.login .iniciar-sesin {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.login .no-tienes-una-cuent {
  color: transparent;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 12px;
  min-height: 29px;
  min-width: 336px;
  text-align: center;
  white-space: nowrap;
}

.login .span {
  color: var(--shark);
  font-weight: 400;
}

.login .span1 {
  color: var(--e30746);
  cursor: pointer;
  font-weight: 700;
}

.input-wrapper-login-form {
  background-color: #f1f1f1;
  border-radius: 28px;
  height: 40px;
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-content: center;
  padding: 0 30px;
}

.input-login-form {
  background-color: transparent;
  letter-spacing: 0;
  width: 100%;
}

.input-login-form::placeholder {
  color: #84848499;
}

.login .frame-1831 {
  align-items: center;
  display: flex;
  margin-top: 21px;
  min-width: 336px;
}

.login .olvidaste-tu-contrasea {
  color: var(--e30746);
  cursor: pointer;
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.5px;
  margin: 0 auto 2.0px 0;
  min-height: 29px;
  white-space: nowrap;
}

.login .frame-8 {
  align-items: flex-start;
  background-color: var(--e30746);
  border-radius: 34px;
  display: flex;
  height: 39px;
  justify-content: flex-end;
  padding: 4px 25.0px;
}

.login .icon-paper_plane {
  align-self: center;
  height: 19px;
  width: 19px;
}

.login .enviar {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 12px;
  min-height: 29px;
  white-space: nowrap;
}

:root { 
  --bdazzled-blue: #475993;
  --black: #000000;
  --e30746: #e30746;
  --gunsmoke: #848484;
  --oslo-gray: #8a898e;
  --quill-gray: #d3d3d3;
  --shamrock: #34e0a1;
  --shark: #272727;
  --white: #ffffff;
 
  --font-size-m: 18px;
  --font-size-s: 16px;
  --font-size-xl2: 36px;
  --font-size-xs: 15px;
  --font-size-xs2: 12px;
  --font-size-xxl: 52px;
  --font-size-xxs: 14px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
}
.montserrat-normal-shark-18px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 600;
}

.montserrat-medium-shark-18px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.montserrat-bold-red-ribbon-14px {
  color: var(--e30746);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.montserrat-light-quill-gray-50px {
  color: var(--quill-gray);
  font-family: var(--font-family-montserrat);
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
}

.montserrat-bold-oslo-gray-14px {
  color: var(--oslo-gray);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.montserrat-bold-shark-52px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}
@media only screen and (max-width: 850px) {
  .montserrat-bold-shark-52px {
    font-size:  var(--font-size-xl2);;
  }
}

.montserrat-bold-shark-36px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl2);
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-shark-15px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-style: normal;
  font-weight: 400;
}

.montserrat-medium-gunsmoke-12px {
  color: var(--gunsmoke);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  font-style: normal;
  font-weight: 500;
}

:root { 
  --black: #000000;
  --blue-ribbon: #0065ff;
  --broom: #ffe924;
  --gunsmoke: #848484;
  --salmon-pearl: #f04c4c;
  --white: #ffffff;
 
  --font-size-l: 19px;
  --font-size-xs2: 12px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
}
.montserrat-bold-white-19px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 700;
}

.montserrat-medium-white-19px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-white-12px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  font-style: normal;
  font-weight: 500;
}

.montserrat-medium-gunsmoke-12px {
  color: var(--gunsmoke);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  font-style: normal;
  font-weight: 500;
}

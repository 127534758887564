:root { 
  --apple: #389e36;
  --black: #000000;
  --blue-ribbon: #0065ff;
  --broom: #ffe924;
  --e30746: #e30746;
  --gunsmoke: #848484;
  --salmon-pearl: #f04c4c;
  --seashell: #f1f1f1;
  --shark: #272727;
  --white: #ffffff;
 
  --font-size-m: 18px;
  --font-size-s: 16px;
  --font-size-xl: 35px;
  --font-size-xl2: 24px;
  --font-size-xs2: 12px;
  --font-size-xxxl: 70px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
}
.montserrat-medium-shark-18px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.montserrat-bold-shark-24px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl2);
  font-style: normal;
  font-weight: 700;
}

.montserrat-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 600;
}

.montserrat-normal-shark-18px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-medium-gunsmoke-12px {
  color: var(--gunsmoke);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  font-style: normal;
  font-weight: 500;
}

:root { 
  --black: #000000;
  --e30746: #e30746;
  --gunsmoke: #848484;
  --shark: #272727;
  --white: #ffffff;
 
  --font-size-s2: 16px;
  --font-size-xl: 36px;
  --font-size-xs: 15px;
  --font-size-xs2: 12px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
}
.montserrat-medium-gunsmoke-12px {
  color: var(--gunsmoke);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s2);
  font-style: normal;
  font-weight: 600;
}

/* screen - landing */

.landing {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 1440px;
}

.landing .overlap-group10 {
  height: 607px;
  position: relative;
  width: 100%;
}

.landing .rectangle-13 {
  background-color: var(--white);
  height: 136px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing .image-24 {
  height: 607px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.landing .vive-una-experiencia-nica {
  color: var(--e30746);
  font-family: var(--font-family-montserrat);

  font-weight: 800;
  letter-spacing: 0;
  line-height: 81px;
  opacity: 0;
  transform: scale(1.1);
  width: 67%;
}

.landing .vive-una-experiencia-nica.animate-enter11 {
  animation: animate-enter11-frames 1.00s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter11-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .visitas-inolvidables-en-granada {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 2%;
}

.landing .frame-1830 {
  place-content: center;
  border-radius: 12px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  flex-direction: column;
  left: 77.6%;
  position: absolute;
  top: 20%;
}

.landing .x1000000-happy-clients {
  place-self: center;
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxs);
  font-weight: 700;
  letter-spacing: 0;
  min-height: 17px;
}

.landing .frame-1828 {
  align-items: flex-start;
  display: flex;
  height: 15px;
  justify-content: flex-start;
  margin-top: 8px;
}

.landing .estrella {
  align-self: center;
  height: 13px;
  width: 14px;
}

.landing .text-1 {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  font-weight: 700;
  letter-spacing: 0;
  margin-left: 8px;
  margin-top: -1px;
  min-height: 15px;
  min-width: 20px;
}

.landing .x15k-reviews {
  color: var(--oslo-gray);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  font-weight: 500;
  letter-spacing: 0;
  margin-left: 8px;
  margin-top: -1px;
  min-height: 15px;
}

.landing .group-1764 {
  align-items: center;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  height: 83px;
  justify-content: flex-end;
  left: 135px;
  min-width: 25.1%;
  padding: 6.5px 14.8px;
  position: absolute;
  top: 381px;
}

.landing .overlap-group8 {
  align-self: flex-end;
  height: 64px;
  position: relative;
  width: 19%;
  margin-right: 9px;
}

.rectangle-landing-product-search {
  height: 40px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 19%;
}

.landing .group-1762 {
  height: 14px;
  left: 22px;
  position: absolute;
  top: 25px;
  width: 14px;
}

.landing .line-4 {
  height: 64px;
  left: 105px;
  position: absolute;
  top: 0;
  width: 2px;
}

.landing .group-1763 {
  height: 40px;
  width: 40px;
}

.landing .que-deseas-buscar {
  background-color: transparent;
  border: 0;
  height: 15px;
  letter-spacing: 0;
  padding: 0;
  width: 10%;
}

.landing .que-deseas-buscar::placeholder {
  color: #84848499;
}

.landing .group-1838 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 4.0px;
  margin-top: 74px;
  min-height: 77px;
  width: 57.2%;
}

.landing .ms-populares {
  letter-spacing: 6.30px;
  line-height: 28.5px;
  margin-right: 6.0px;
  min-height: 29px;
  min-width: 14.1%;
  text-align: center;
  white-space: nowrap;
}

.landing .granada-en-todo-su-esplendor {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-right: 4.0px;
  margin-top: 19px;
  min-height: 29px;
  min-width: 57%;
  opacity: 0;
  text-align: center;
  transform: scale(1.1);
  white-space: nowrap;
}

.landing .granada-en-todo-su-esplendor.animate-enter2 {
  animation: animate-enter2-frames 1.00s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter2-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .group-1836 {
  align-items: flex-start;
  display: flex;
  margin-top: 67px;
  width: 80.7%;
}

.landing .frame-12 {
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  height: 554px;
  opacity: 0;
  position: relative;
  transform: translate(25px, 0);
  width: 24.8%;
}

.landing .frame-12.animate-enter13 {
  animation: animate-enter13-frames 1.00s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

@keyframes animate-enter13-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

.landing .frame-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 37px;
  min-height: 405px;
  position: absolute;
  top: 43px;
  width: 19.7%;
}

.landing .rectangle-14-1 {
  height: 228px;
  object-fit: cover;
  width: 19.7%;
}

.landing .frame-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  min-height: 147px;
  width: 18.6%;
}

.landing .frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  width: 18.6%;
}

.landing .place {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .visita-guiada-sin-colas {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 12px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .frame-6 {
  align-items: flex-start;
  display: flex;
  height: 29px;
  margin-top: 48px;
  min-width: 157px;
}

.landing .group-1741 {
  align-self: center;
  height: 22px;
  margin-left: -1px;
  width: 22px;
}

.landing .esp-eng-fra {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .frame-10 {
  align-items: center;
  display: flex;
  height: 39px;
  left: 36px;
  min-width: 286px;
  position: absolute;
  top: 472px;
}

.landing .frame-2 {
  align-items: flex-start;
  display: flex;
  height: 29px;
  justify-content: flex-end;
  min-width: 53px;
}

.landing .union {
  align-self: center;
  height: 24px;
  width: 24px;
}

.landing .x3-h {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 4px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .frame-8 {
  align-items: flex-start;
  background-color: var(--e30746);
  border-radius: 34px;
  display: flex;
  height: 39px;
  margin-left: 43px;
  min-width: 190px;
  padding: 4px 25.0px;
}

.landing .vector {
  align-self: center;
  height: 15px;
  margin-bottom: 0;
  width: 15px;
}

.landing .desde {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 5px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .frame-13 {
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  height: 554px;
  margin-left: 45px;
  opacity: 0;
  position: relative;
  transform: translate(25px, 0);
  width: 24.8%;
}

.landing .frame-13.animate-enter14 {
  animation: animate-enter14-frames 1.00s ease-in-out 0.50s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

@keyframes animate-enter14-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

.landing .rectangle-1 {
  background-color: transparent;
  height: 228px;
  margin-left: 0;
  object-fit: cover;
  width: 19.7%;
}

.landing .alpujarra {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .visita-guiada-sin-colas-1 {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 12px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .frame-6-1 {
  align-items: flex-start;
  display: flex;
  height: 29px;
  margin-top: 48px;
  min-width: 64px;
}

.landing .fra {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .frame-13-1 {
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  height: 554px;
  margin-left: 45px;
  opacity: 0;
  position: relative;
  transform: translate(25px, 0);
  width: 24.7%;
}

.landing .frame-13-1.animate-enter15 {
  animation: animate-enter15-frames 1.00s ease-in-out 1.00s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

@keyframes animate-enter15-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

.landing .frame-9-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 36px;
  min-height: 405px;
  position: absolute;
  top: 43px;
  width: 19.7%;
}

.landing .name {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .todas-las-instalaciones-disponibles {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 12px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .frame-6-2 {
  align-items: flex-start;
  display: flex;
  height: 29px;
  margin-top: 48px;
  min-width: 113px;
}

.landing .eng-fra {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.landing .frame-10-1 {
  align-items: center;
  display: flex;
  height: 39px;
  left: 36px;
  min-width: 285px;
  position: absolute;
  top: 472px;
}

.landing .frame-2-1 {
  align-items: flex-start;
  display: flex;
  height: 29px;
  min-width: 53px;
}

.landing .frame-8-1 {
  align-items: flex-start;
  background-color: var(--e30746);
  border-radius: 34px;
  display: flex;
  height: 39px;
  margin-left: 43px;
  min-width: 189px;
  padding: 4px 25.0px;
}

.landing .overlap-group11 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(227, 7, 70) 0%, rgb(178.5, 0, 51.12) 100%);
  display: flex;
  height: 695px;
  margin-top: 82px;
  width: 100%;
  padding: 87.4px 138.1px;
}

.landing .frame-16 {
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  height: 504px;
  opacity: 0;
  position: relative;
  transform: scale(1.1);
  width: 100%;
}

.landing .frame-16.animate-enter12 {
  animation: animate-enter12-frames 1.00s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter12-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .visita-todos-sus-rincones {
  color: var(--e30746);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl2);
  font-weight: 700;
  left: 69px;
  letter-spacing: 0;
  line-height: 28.5px;
  position: absolute;
  top: 31px;
  white-space: nowrap;
}
@media only screen and (max-width: 950px) {
  .landing .visita-todos-sus-rincones {
    font-size: 25px;  
  }
}


.landing .frame-14 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  left: 4.7%;
  min-height: 385px;
  position: absolute;
  top: 20%;
  width: 90%;
}

.landing .group-1812 {
  align-items: flex-end;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bab950d3ed8532d0c3cc19/img/rectangle-288@1x.png);
  background-size: 100% 100%;
  display: flex;
  height: 316px;
  width: 100%;
  padding: 24.6px 46.2px;
}

.landing .rectangle-1-1 {
  background-color: var(--white);
  border-radius: 28px;
  height: 14px;
  width: 14px;
}

.landing .rectangle-28 {
  background-color: var(--white);
  border-radius: 28px;
  height: 14px;
  margin-left: 12px;
  opacity: 0.55;
  width: 14px;
}

.landing .frame-9-2 {
  align-items: flex-start;
  background-color: var(--e30746);
  border-radius: 34px;
  display: flex;
  height: 39px;
  margin-top: 30px;
  min-width: 186px;
  padding: 4px 25.0px;
}

.landing .group-1837 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 4.0px;
  margin-top: 87px;
  min-height: 77px;
  width: 42.1%;
}
@media only screen and (max-width: 1100px) {
  .landing .group-1837 {
    width: 100% !important;
  }
  .landing .todos-los-lugares-de-granada-en-tu-mano {
    width: 100% !important;
  }
}




.landing .todos-los-lugares-de-granada-en-tu-mano {
 letter-spacing: 6.30px;
  line-height: 28.5px;
  margin-right: 4.0px; 
  min-height: 29px;
  /* width: 45%; */
  text-align: center;
  white-space: nowrap; 
}

.landing .conoce-las-aventuras {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-right: 4.0px;
  margin-top: 19px;
  min-height: 29px;
  min-width: 579px;
  text-align: center;
  white-space: nowrap;
}

.landing .overlap-group9 {
  height: 544px;
  margin-top: 53px;
  position: relative;
  width: 648px;
}

.landing .group-1782 {
  display: block;
  height: 544px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 648px;
}

.landing .group-1782.animate-enter {
  animation: animate-enter-frames 1.00s ease-in-out 0.00s 1 normal forwards;
  display: block;
  opacity: 0;
}

@keyframes animate-enter-frames {
  from{opacity: 0;
}
to{opacity: 1;
}
}

.landing .place-1 {
  color: #a3a3a3;
  font-family: var(--font-family-montserrat);
  font-size: 21px;
  font-weight: 700;
  left: 277px;
  letter-spacing: 0;
  line-height: 28.5px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 308px;
  white-space: nowrap;
}

.landing .place-1.animate-enter1 {
  animation: animate-enter1-frames 1.00s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
}

@keyframes animate-enter1-frames {
  from{opacity: 0;
}
to{opacity: 1;
}
}

.landing .group-1839 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 110px;
  min-height: 407px;
  position: absolute;
  top: 95px;
  width: 505px;
}

.landing .group-1175 {
  align-self: flex-end;
  background-color: var(--white);
  border-radius: 17.71px;
  box-shadow: 0px 10.01px 10.01px #86868624;
  height: 35px;
  opacity: 0;
  transform: scale(1.1);
  width: 35px;
}

.landing .group-1175.animate-enter8 {
  animation: animate-enter8-frames 1.00s ease-in-out 1.20s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter8-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .ellipse-container {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62baba8a2be4dd508a875648/img/ellipse-180-5@2x.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 29px;
}

.landing .ellipse-180-stroke {
  height: 29px;
  left: 0;
  position: absolute;
  top: 0;
  width: 29px;
}

.landing .ellipse-181-1 {
  background-color: var(--e30746);
  border-radius: 6.39px;
  height: 13px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 13px;
}

.landing .group-1181 {
  align-self: center;
  background-color: var(--white);
  border-radius: 12.81px;
  box-shadow: 0px 7.24px 7.24px #86868624;
  height: 26px;
  margin-left: 223.68px;
  margin-top: 82px;
  opacity: 0;
  transform: scale(1.1);
  width: 26px;
}

.landing .group-1181.animate-enter4 {
  animation: animate-enter4-frames 1.00s ease-in-out 1.10s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter4-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .ellipse-container-1 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62baba8a2be4dd508a875648/img/ellipse-180-1@2x.svg);
  background-size: 100% 100%;
  height: 21px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 21px;
}

.landing .ellipse-180-stroke-2 {
  height: 21px;
  left: 0;
  position: absolute;
  top: 0;
  width: 21px;
}

.landing .ellipse-181-2 {
  background-color: var(--e30746);
  border-radius: 4.62px;
  height: 9px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 9px;
}

.landing .flex-row-1 {
  align-items: center;
  display: flex;
  height: 250px;
  min-width: 409px;
}

.group-container-landing {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 157px;
  width: 49px;
}

.landing .group-1834 {
  background-color: var(--white);
  border-radius: 20.91px;
  box-shadow: 0px 9.7px 9.7px #86868624;
  height: 42px;
  opacity: 0;
  transform: scale(1.1);
  width: 42px;
}

.landing .group-1834.animate-enter7 {
  animation: animate-enter7-frames 1.00s ease-in-out 0.60s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter7-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .ellipse-container-2 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62baba8a2be4dd508a875648/img/ellipse-180-4@2x.svg);
  background-size: 100% 100%;
  height: 34px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 34px;
}

.landing .ellipse-180-stroke-3 {
  height: 34px;
  left: 0;
  position: absolute;
  top: 0;
  width: 34px;
}

.landing .ellipse-181-3 {
  background-color: var(--e30746);
  border-radius: 7.54px;
  height: 15px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 15px;
}

.landing .group-1180 {
  align-self: flex-end;
  background-color: var(--white);
  border-radius: 17.66px;
  box-shadow: 0px 9.98px 9.98px #86868624;
  height: 35px;
  margin-top: 80px;
  opacity: 0;
  transform: scale(1.1);
  width: 35px;
}

.landing .group-1180.animate-enter5 {
  animation: animate-enter5-frames 1.00s ease-in-out 0.80s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter5-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .ellipse-container-3 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62baba8a2be4dd508a875648/img/ellipse-180-2@2x.svg);
  background-size: 100% 100%;
  height: 29px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 29px;
}

.landing .ellipse-181-4 {
  background-color: var(--e30746);
  border-radius: 6.37px;
  height: 13px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 13px;
}

.landing .group-container-1 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 37px;
  min-height: 228px;
  width: 52px;
}

.landing .group-1183 {
  background-color: var(--white);
  border-radius: 16.89px;
  box-shadow: 0px 9.54px 9.54px #86868624;
  height: 34px;
  opacity: 0;
  transform: scale(1.1);
  width: 34px;
}

.landing .group-1183.animate-enter9 {
  animation: animate-enter9-frames 1.00s ease-in-out 1.00s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter9-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .ellipse-container-4 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62baba8a2be4dd508a875648/img/ellipse-180-6@2x.svg);
  background-size: 100% 100%;
  height: 28px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 28px;
}

.landing .ellipse-180-stroke-1 {
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.landing .ellipse-181 {
  background-color: var(--e30746);
  border-radius: 6.09px;
  height: 12px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 12px;
}

.landing .group-1176 {
  align-self: flex-end;
  background-color: var(--white);
  border-radius: 9.77px;
  box-shadow: 0px 5.52px 5.52px #86868624;
  height: 20px;
  margin-top: 175px;
  opacity: 0;
  transform: scale(1.1);
  width: 20px;
}

.landing .group-1176.animate-enter3 {
  animation: animate-enter3-frames 1.00s ease-in-out 0.70s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter3-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .ellipse-container-5 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62baba8a2be4dd508a875648/img/ellipse-180@2x.svg);
  background-size: 100% 100%;
  height: 16px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 16px;
}

.landing .ellipse-180-stroke-4 {
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.landing .ellipse-181-5 {
  background-color: var(--e30746);
  border-radius: 3.52px;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 5px;
  width: 7px;
}

.landing .group-1184 {
  background-color: var(--white);
  border-radius: 17.17px;
  box-shadow: 0px 9.7px 9.7px #86868624;
  height: 34px;
  margin-left: 76px;
  margin-top: 39.61px;
  opacity: 0;
  transform: scale(1.1);
  width: 34px;
}

.landing .group-1184.animate-enter6 {
  animation: animate-enter6-frames 1.00s ease-in-out 0.60s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter6-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .ellipse-container-6 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62baba8a2be4dd508a875648/img/ellipse-180-3@2x.svg);
  background-size: 100% 100%;
  height: 28px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 28px;
}

.landing .ellipse-181-6 {
  background-color: var(--e30746);
  border-radius: 6.19px;
  height: 12px;
  left: 8px;
  position: absolute;
  top: 8px;
  width: 12px;
}

.landing .group-1730 {
  background-color: var(--white);
  border-radius: 16.89px;
  box-shadow: 0px 9.54px 9.54px #86868624;
  height: 34px;
  margin-bottom: 82.05px;
  margin-left: 126px;
  opacity: 0;
  transform: scale(1.1);
  width: 34px;
}

.landing .group-1730.animate-enter10 {
  animation: animate-enter10-frames 1.00s ease-in-out 1.10s 1 normal forwards;
  opacity: 0;
  transform: scale(1.1);
}

@keyframes animate-enter10-frames {
  from{opacity: 0;
transform: scale(1.1);
}
to{opacity: 1;
transform: scale(1);
}
}

.landing .ellipse-container-7 {
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62baba8a2be4dd508a875648/img/ellipse-180-7@2x.svg);
  background-size: 100% 100%;
  height: 28px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 28px;
}

.landing .group-1799 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(227, 7, 70) 0%, rgb(178.5, 0, 51.12) 100%);
  border-radius: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  min-height: 895px;
  padding: 89.9px 96.9px;
  width: 81.3%;
}
@media only screen and (max-width: 950px) {
  .landing .group-1799{
    min-height: min-content;
  }
}

.landing .group-12 {
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-right: 3.26px;
  min-height: 77px;
  width: 29.1%;
}

.landing .nuestros-clientes {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxs);
  font-weight: 700;
  letter-spacing: 6.30px;
  line-height: 28.5px;
  margin-right: 4.0px;
  min-height: 29px;
  /*min-width: 256px;*/
  text-align: center;
  white-space: nowrap;
}

.landing .sus-referencias {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.5px;
  margin-right: 4.0px;
  margin-top: 19px;
  min-height: 29px;
  min-width: 416px;
  text-align: center;
  white-space: nowrap;
}

.landing .overlap-group-container {
  align-items: flex-start;
  display: flex;
  margin-top: 101px;
  width: 60%;
}
@media only screen and (max-width: 1350px) {
  .landing .overlap-group-container {
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center !important;
    display: flex;
  }
  .landing .flex-row-2 {
    margin-top: 30px;
    margin-left: 0px !important;
  }
}


.landing .flex-row {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  height: 237px;
  justify-content: flex-end;
  min-width: 470px;
  padding: 12.0px 28px;
}

.landing .flex-col-100 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24.0px;
  min-height: 112px;
  width: 70px;
}

.landing .mask {
  height: 70px;
  object-fit: cover;
  width: 70px;
}

.landing .group-173 {
  align-items: flex-start;
  background-color: var(--shamrock);
  border-radius: 24px;
  display: flex;
  height: 29px;
  margin-left: 0;
  margin-top: 12px;
  min-width: 44px;
  padding: 2.2px 10.2px;
}

.landing .tripadvisor-svgrepo-com-1 {
  height: 24px;
  width: 24px;
}

.landing .flex-col-1 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  min-height: 202px;
  width: 307px;
}

.landing .prototyping-in-the-b {
  letter-spacing: 0;
  line-height: 28px;
  height: 168px;
  width: 307px;
}

.landing .timothy-nathan {
  letter-spacing: 2.00px;
  line-height: 26px;
  margin-top: 8px;
  min-height: 26px;
  white-space: nowrap;
}

.landing .flex-row-2 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  height: 237px;
  justify-content: flex-end;
  margin-left: 30px;
  min-width: 470px;
  padding: 12.0px 28px;
}

.landing .flex-col-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 24.04px;
  min-height: 112px;
  width: 70px;
}

.landing .overlap-group-container-1 {
  align-items: flex-start;
  display: flex;
  margin-top: 37px;
  width: 60%;
}
@media only screen and (max-width: 1350px) {
  .landing .overlap-group-container-1 {
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-items: center !important;
    display: flex;
  }
  .landing .flex-row-3 {
    margin-top: 30px;
    margin-left: 0px !important;
  }
}

.landing .group-179 {
  align-items: flex-end;
  background-color: var(--bdazzled-blue);
  border-radius: 24px;
  display: flex;
  height: 29px;
  margin-left: 0;
  margin-top: 12px;
  min-width: 44px;
  padding: 6.5px 18.2px;
}
.landing .group-180 {
  align-items: flex-end;
  border: lightgrey 1px solid;
  border-radius: 24px;
  display: flex;
  height: 29px;
  margin-left: 0;
  margin-top: 12px;
  min-width: 44px;
  padding: 6.5px 18.2px;
}

.landing .icon-facebook {
  height: 15px;
  width: 8px;
}

.landing .icon-google {
  width: 16px;
}

.landing .flex-row-3 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  height: 237px;
  justify-content: flex-end;
  margin-left: 30px;
  min-width: 470px;
  padding: 11.0px 28px;
}

.landing .flex-col-3 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 25.0px;
  min-height: 112px;
  width: 70px;
}

.landing .group-13 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 5.0px;
  margin-top: 3%;
  min-height: 77px;
  width: 18.6%;
}

.landing .nuestros-partners {
  letter-spacing: 6.30px;
  line-height: 28.5px;
  margin-right: 4.0px;
  min-height: 29px;
  min-width: 265px;
  text-align: center;
  white-space: nowrap;
}

.landing .partners {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-right: 6.0px;
  margin-top: 19px;
  min-height: 29px;
  min-width: 235px;
  text-align: center;
  white-space: nowrap;
}

.landing .group-1775 {
  align-items: center;
  display: flex;
  height: 5rem;
  margin: auto;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
  min-width: 74.8%;
}

.landing .logo-1 {
  letter-spacing: 3.25px;
  line-height: 28.5px;
  min-height: 29px;
  min-width: 161px;
  white-space: nowrap;
}
@media only screen and (max-width: 1350px) {
  .landing .group-1775 {
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    margin: 15%;
  }
  .landing .logo-3{
    margin-left: 0px !important;
    margin-top:  5%;
  }
  .landing .logo-2{
    margin-left: 0px !important;
    margin-top: 5%;
  }
}

.landing .logo-3 {
  letter-spacing: 3.25px;
  line-height: 28.5px;
  margin-left: 142px;
  min-height: 29px;
  min-width: 161px;
  white-space: nowrap;
}

.landing .logo-2 {
  letter-spacing: 3.25px;
  line-height: 28.5px;
  margin-left: 142px;
  min-height: 29px;
  white-space: nowrap;
}

.andalucia-map{
  background-image: url(@src/assets/images/components/b2c/landing/andalusia_tours_map/svg3268@1x.svg);
  background-size: 100% 100%;
  display: flex;
  width: 100%;

}
:root { 
  --black: #000000;
  --delta: #a3a3a3;
  --red-ribbon: #e30746;
  --white: #ffffff;
 
  --font-size-m: 21px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
}

.montserrat-bold-delta-21px {
  color: var(--delta);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

:root { 
  --apple: #389e36;
  --black: #000000;
  --blue-ribbon: #0065ff;
  --broom: #ffe924;
  --e30746: #e30746;
  --gunsmoke: #848484;
  --mine-shaft: #333333;
  --salmon-pearl: #f04c4c;
  --shark: #272727;
  --star-dust: #9b9b9b;
  --white: #ffffff;
 
  --font-size-m: 18px;
  --font-size-s: 17px;
  --font-size-s2: 16px;
  --font-size-xl2: 36px;
  --font-size-xl3: 24px;
  --font-size-xs2: 15px;
  --font-size-xxl: 50px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
}
.montserrat-bold-red-ribbon-16px {
  color: var(--e30746);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s2);
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-shark-18px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.montserrat-semi-bold-shark-18px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.montserrat-bold-shark-24px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl3);
  font-style: normal;
  font-weight: 700;
}

.montserrat-semi-bold-white-16px {
  color: var(--white);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s2);
  font-style: normal;
  font-weight: 600;
}

.montserrat-medium-shark-18px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.montserrat-normal-mine-shaft-17px {
  color: var(--mine-shaft);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.montserrat-medium-star-dust-16px {
  color: var(--star-dust);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-s2);
  font-style: normal;
  font-weight: 500;
}

.montserrat-semi-bold-shark-22px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-semi-bold-red-ribbon-23px {
  color: var(--e30746);
  font-family: var(--font-family-montserrat);
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
}

.montserrat-bold-shark-36px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl2);
  font-style: normal;
  font-weight: 700;
}

.montserrat-normal-shark-15px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs2);
  font-style: normal;
  font-weight: 400;
}

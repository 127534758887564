.ekomi-widget-container {
  margin: 0 auto;
}

.ekw150.pos-bottom_left {
  position: inherit;
}

.ekw150 .tooltipp {
  position: relative !important;
  left: -605px !important;
  top: -300px !important;
}

.cross-widget {
  display: none;
}

.ekw150.pos-bottom_left .arrow-tooltip {
  bottom: 25px !important;
  right: -7px;
  left: unset !important;
  transform: rotate(270deg);
}
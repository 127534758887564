:root { 
  --black: #000000;
  --e30746: #e30746;
  --salmon-pearl: #f04c4c;
  --shark: #272727;
  --white: #ffffff;
 
  --font-size-m: 17px;
  --font-size-xs: 15px;
 
  --font-family-montserrat: "Montserrat", Helvetica;
}
.montserrat-medium-shark-17px {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

/* screen - pedidos */

.pedidos {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 67px 9.3%;
  max-width: 1440px;
  width: 100%;
}

.pedidos .title {
  align-self: flex-start;
  color: var(--e30746);
  font-family: var(--font-family-montserrat);
  font-size: 50px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 34px;
  margin-left: 0.08px;
  min-height: 34px;
  white-space: nowrap;
}

.pedidos .frame-1973 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 49px;
  width: 100%;
}

.pedidos .frame-1946 {
  align-items: center;
  display: flex;
  min-width: 1168px;
}

.pedidos .frame-1945 {
  align-items: center;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  height: 81px;
  min-width: 1098px;
  padding: 0 21px;
}

.pedidos .frame-1943 {
  align-items: center;
  display: flex;
  min-width: 272px;
}

.pedidos .frame-1941 {
  align-items: flex-start;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  margin-top: 0;
  min-width: 145px;
}

.pedidos .icon-cart {
  align-self: center;
  height: 23px;
  width: 23px;
}

.pedidos .date {
  letter-spacing: 0;
  line-height: 34px;
  margin-left: 10px;
  margin-top: -1px;
  min-height: 34px;
  white-space: nowrap;
}

.pedidos .rectangle-315 {
  background-color: var(--e30746);
  height: 51px;
  margin-left: 16px;
  width: 1px;
}

.pedidos .frame-1942 {
  align-items: flex-start;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  margin-left: 16px;
  min-width: 94px;
}

.pedidos .group {
  align-self: center;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/union-3@2x.svg);
  background-size: 100% 100%;
  height: 13px;
  margin-top: 0;
  width: 26px;
}

.pedidos .address {
  letter-spacing: 0;
  line-height: 34px;
  margin-left: 10px;
  margin-top: -1px;
  min-height: 34px;
  white-space: nowrap;
}

.pedidos .frame-1944 {
  align-items: flex-start;
  display: flex;
  height: 34px;
  margin-left: 633px;
  min-width: 151px;
}

.pedidos .total-65 {
  letter-spacing: 0;
  line-height: 34px;
  margin-top: -1px;
  min-height: 34px;
  min-width: 102px;
  white-space: nowrap;
}

.pedidos .vector {
  align-self: center;
  height: 7px;
  margin-left: 36px;
  margin-top: 0;
  width: 13px;
}

.pedidos .cancelar {
  height: 40px;
  margin-left: 30px;
  width: 40px;
}

.pedidos .frame-1947 {
  align-items: center;
  display: flex;
  margin-top: 39px;
  min-width: 1168px;
}

.pedidos .date-1 {
  letter-spacing: 0;
  line-height: 34px;
  margin-left: 10px;
  margin-top: -1px;
  min-height: 34px;
  white-space: nowrap;
}

.pedidos .group-1 {
  align-self: center;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/union-4@2x.svg);
  background-size: 100% 100%;
  height: 13px;
  margin-top: 0;
  width: 26px;
}

.pedidos .address-1 {
  letter-spacing: 0;
  line-height: 34px;
  margin-left: 10px;
  margin-top: -1px;
  min-height: 34px;
  white-space: nowrap;
}

.pedidos .group-1864 {
  align-items: flex-start;
  display: flex;
  margin-top: 39px;
  min-width: 1168px;
}

.pedidos .frame-1972 {
  align-items: center;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.pedidos .frame-1970 {
  align-items: center;
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.pedidos .frame-1943-1 {
  align-items: center;
  display: flex;
}

.pedidos .date-2 {
  letter-spacing: 0;
  line-height: 34px;
  margin-left: 10px;
  margin-top: -1px;
  min-height: 34px;
  white-space: nowrap;
}

.pedidos .frame-1942-1 {
  align-items: flex-start;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  margin-left: 16px;
  min-width: 108px;
}

.pedidos .frame-1942-2 {
  align-items: flex-start;
  display: flex;
  height: 34px;
  justify-content: center;
  margin-left: 16px;
  min-width: 108px;
}

.pedidos .group-2 {
  align-self: center;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62bdbd04ad7f05c93f81a613/img/union-5@2x.svg);
  background-size: 100% 100%;
  height: 13px;
  margin-top: 0;
  width: 26px;
}

.pedidos .address-2 {
  letter-spacing: 0;
  line-height: 34px;
  margin-left: 10px;
  margin-top: -1px;
  min-height: 34px;
  white-space: nowrap;
}

.pedidos .address-3 {
  letter-spacing: 0;
  line-height: 34px;
  margin-top: -1px;
  min-height: 34px;
  white-space: nowrap;
}

.pedidos .frame-1968 {
  align-items: flex-start;
  display: flex;
  height: 34px;
}

.pedidos .total-156 {
  letter-spacing: 0;
  line-height: 34px;
  margin-top: -1px;
  min-height: 34px;
  min-width: 109px;
  text-align: right;
  white-space: nowrap;
}

.pedidos .vector-2 {
  align-self: center;
  height: 7px;
  margin-bottom: 0;
  margin-left: 36px;
  width: 13px;
}

.pedidos .frame-1969 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 38px 0;
  width: 974px;
}

.pedidos .frame-1965 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 974px;
}

.pedidos .frame-1964 {
  align-items: center;
  justify-content: space-between;
  display: flex;
  min-width: 974px;
}

.pedidos .frame-1963 {
  align-items: center;
  display: flex;
  min-width: 456px;
}

.pedidos .image-24 {
  margin-top: 20px;
  height: 150px;
  object-fit: cover;
  width: 172px;
}

.pedidos .frame-1962 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 18px;
  min-height: 129px;
  width: 293px;
}

.pedidos .prototyping-in-the-b {
  letter-spacing: 0;
  line-height: 30.5px;
  margin-top: -1px;
  min-height: 31px;
  white-space: nowrap;
}

.pedidos .frame-1960 {
  align-items: center;
  display: flex;
  margin-top: 8px;
  min-width: 293px;
}

.pedidos .frame-1950 {
  align-items: center;
  display: flex;
  height: 91px;
  min-width: 104px;
}

.pedidos .frame-1949 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 80px;
  width: 20px;
  margin-top: 10px;
}

.pedidos .group-1862 {
  height: 20px;
  margin-left: 0.25px;
  width: 20px;
}

.pedidos .icon-sand_timer {
  height: 19px;
  margin-left: 2.04px;
  margin-top: 10px;
  width: 16px;
}

.pedidos .union {
  height: 20px;
  margin-top: 10px;
  width: 20px;
}

.pedidos .frame-1948 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  min-height: 91px;
  width: 78px;
}

.pedidos .x3-h-1 {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .x1800-pm {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .rectangle-316 {
  background-color: var(--e30746);
  height: 80px;
  margin-left: 16px;
  width: 1px;
}

.pedidos .frame-1959 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  min-height: 79px;
  width: 156px;
}

.pedidos .frame-1956 {
  align-items: flex-start;
  display: flex;
  min-width: 153px;
}

.pedidos .frame-1953 {
  align-items: flex-start;
  display: flex;
  height: 19px;
  min-width: 102px;
}

.pedidos .esp {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 18px;
  width: 22px;
}

.pedidos .rectangle-14 {
  background-color: var(--salmon-pearl);
  height: 6px;
  margin-left: 0;
  width: 22px;
}

.pedidos .rectangle-15 {
  background-color: var(--broom);
  height: 6px;
  width: 22px;
}

.pedidos .rectangle-16 {
  background-color: var(--salmon-pearl);
  height: 6px;
  width: 22px;
}

.pedidos .prototyping-in-the-b-1 {
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 10px;
  margin-top: -1px;
  min-height: 19px;
  white-space: nowrap;
}

.pedidos .prototyping-in-the-b-2 {
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 25px;
  margin-top: -1px;
  min-height: 19px;
  white-space: nowrap;
}

.pedidos .frame-1957 {
  align-items: flex-start;
  display: flex;
  margin-top: 11px;
  min-width: 153px;
}

.pedidos .frame-1954 {
  align-items: flex-start;
  display: flex;
  height: 19px;
  min-width: 83px;
}

.pedidos .esp-1 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 18px;
  width: 22px;
}

.pedidos .prototyping-in-the-b-3 {
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 44px;
  margin-top: -1px;
  min-height: 19px;
  white-space: nowrap;
}

.pedidos .frame-1958 {
  align-items: flex-start;
  display: flex;
  margin-top: 11px;
  min-width: 156px;
}

.pedidos .frame-1955 {
  align-items: flex-start;
  display: flex;
  height: 19px;
  min-width: 100px;
}

.pedidos .eng {
  align-self: center;
  height: 18px;
  width: 22px;
}

.pedidos .prototyping-in-the-b-4 {
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 27px;
  margin-top: -1px;
  min-height: 19px;
  white-space: nowrap;
}

.pedidos .frame-1961 {
  align-items: center;
  display: flex;
}

.pedidos .prototyping-in-the-b-5 {
  letter-spacing: 0;
  line-height: 30.5px;
  margin-bottom: 2.0px;
  min-height: 31px;
  min-width: 122px;
  white-space: nowrap;
}

.pedidos .group-1863 {
  height: 40px;
  margin-left: 20px;
  width: 40px;
}

.pedidos .rectangle-314 {
  background-color: var(--gunsmoke);
  height: 2px;
  margin-top: 20px;
  width: 974px;
}

.pedidos .frame-1966 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  min-height: 151px;
  width: 974px;
}

.pedidos .x3-h-2 {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .x1800-pm-1 {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .esp-2 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 18px;
  width: 22px;
}

.pedidos .esp-3 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 18px;
  width: 22px;
}

.pedidos .eng-1 {
  align-self: center;
  height: 18px;
  width: 22px;
}

.pedidos .frame-1967 {
  align-items: center;
  display: flex;
  margin-top: 20px;
  min-width: 974px;
}

.pedidos .x3-h-3 {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .x1800-pm-2 {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .esp-4 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 18px;
  width: 22px;
}

.pedidos .esp-5 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: 18px;
  width: 22px;
}

.pedidos .eng-2 {
  align-self: center;
  height: 18px;
  width: 22px;
}

.pedidos .cancelar-1 {
  height: 40px;
  margin-left: 30px;
  margin-top: 20px;
  width: 40px;
}

.pedidos .rectangle-301 {
  background-color: var(--e30746);
  height: 2px;
  margin-left: 0.5px;
  margin-top: 67px;
  width: 80.7%;
}

.pedidos .group-1836 {
  align-items: flex-start;
  display: flex;
  margin-left: 0.5px;
  margin-top: 40px;
  min-width: 1163px;
}

.pedidos .frame-12 {
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  height: 554px;
  opacity: 0;
  position: relative;
  transform: translate(25px, 0);
  width: 358px;
}

.pedidos .frame-12.animate-enter {
  animation: animate-enter-frames 1.00s ease-in-out 0.00s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

@keyframes animate-enter-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

.pedidos .frame-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 37px;
  min-height: 405px;
  position: absolute;
  top: 43px;
  width: 284px;
}

.pedidos .rectangle-1 {
  height: 228px;
  margin-left: 0;
  object-fit: cover;
  width: 284px;
}

.pedidos .frame-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  min-height: 147px;
  width: 268px;
}

.pedidos .frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  width: 268px;
}

.pedidos .place {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .visita-guiada-sin-colas {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 12px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .frame-6 {
  align-items: flex-start;
  display: flex;
  height: 29px;
  margin-top: 48px;
  min-width: 157px;
}

.pedidos .group-1741 {
  align-self: center;
  height: 22px;
  margin-left: -1px;
  width: 22px;
}

.pedidos .esp-eng-fra {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .frame-10 {
  align-items: center;
  display: flex;
  height: 39px;
  left: 36px;
  min-width: 286px;
  position: absolute;
  top: 472px;
}

.pedidos .frame-2 {
  align-items: flex-start;
  display: flex;
  height: 29px;
  min-width: 53px;
}

.pedidos .union-1 {
  align-self: center;
  height: 24px;
  width: 24px;
}

.pedidos .x3-h {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 4px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .frame-8 {
  align-items: flex-start;
  background-color: var(--e30746);
  border-radius: 34px;
  display: flex;
  height: 39px;
  margin-left: 43px;
  min-width: 190px;
  padding: 4px 25.0px;
}

.pedidos .vector-1 {
  align-self: center;
  height: 15px;
  margin-bottom: 0;
  width: 15px;
}

.pedidos .desde {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 5px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .frame-13 {
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  height: 554px;
  margin-left: 45px;
  opacity: 0;
  position: relative;
  transform: translate(25px, 0);
  width: 358px;
}

.pedidos .frame-13.animate-enter1 {
  animation: animate-enter1-frames 1.00s ease-in-out 0.50s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

@keyframes animate-enter1-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

.pedidos .alpujarra {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .visita-guiada-sin-colas-1 {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 12px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .frame-6-1 {
  align-items: flex-start;
  display: flex;
  height: 29px;
  margin-top: 48px;
  min-width: 64px;
}

.pedidos .fra {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .frame-13-1 {
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  height: 554px;
  margin-left: 45px;
  opacity: 0;
  position: relative;
  transform: translate(25px, 0);
  width: 357px;
}

.pedidos .frame-13-1.animate-enter2 {
  animation: animate-enter2-frames 1.00s ease-in-out 1.00s 1 normal forwards;
  opacity: 0;
  transform: translate(25px, 0);
}

@keyframes animate-enter2-frames {
  from{opacity: 0;
transform: translate(25px, 0);
}
to{opacity: 1;
transform: translate(0,0);
}
}

.pedidos .frame-9-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 36px;
  min-height: 405px;
  position: absolute;
  top: 43px;
  width: 284px;
}

.pedidos .name {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .todas-las-instalaciones-disponibles {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 12px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .frame-6-2 {
  align-items: flex-start;
  display: flex;
  height: 29px;
  margin-top: 48px;
  min-width: 113px;
}

.pedidos .eng-fra {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 5px;
  margin-top: -1px;
  min-height: 29px;
  white-space: nowrap;
}

.pedidos .frame-10-1 {
  align-items: center;
  display: flex;
  height: 39px;
  left: 36px;
  min-width: 285px;
  position: absolute;
  top: 472px;
}

.pedidos .frame-8-1 {
  align-items: flex-start;
  background-color: var(--e30746);
  border-radius: 34px;
  display: flex;
  height: 39px;
  margin-left: 43px;
  min-width: 189px;
  padding: 4px 25.0px;
}

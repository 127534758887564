/* screen - crear-usuario */

.crear-usuario {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 874.77px;
  width: 1440px;
}

.crear-usuario .overlap-group1 {
  height: 875px;
  margin-top: 0;
  position: relative;
  width: 1440px;
}

.crear-usuario .rectangle-13 {
  background-color: var(--white);
  height: 136px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.crear-usuario .image-35 {
  height: 875px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1440px;
}

.crear-usuario .frame-1846 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  height: 690px;
  left: 479px;
  min-width: 466px;
  padding: 44px 37px;
  position: absolute;
  top: 124px;
}

.crear-usuario .frame-container {
  height: 602px;
  position: relative;
  width: 392px;
}

.crear-usuario .frame-1847 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 573px;
  position: absolute;
  top: 0;
  width: 392px;
}

.crear-usuario .frame-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 70px;
  width: 304px;
}

.crear-usuario .title {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xl2);
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  min-width: 253px;
  text-align: center;
  white-space: nowrap;
}

.crear-usuario .elige-con-que-usuari {
  color: var(--shark);
  font-family: var(--font-family-montserrat);
  font-size: var(--font-size-xs);
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: 12px;
  min-height: 29px;
  min-width: 304px;
  text-align: center;
  white-space: nowrap;
}

.crear-usuario .frame-1844 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  min-height: 420px;
  width: 392px;
}

.crear-usuario .frame-1839 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 1.51px;
  min-height: 102px;
  width: 389px;
}

.crear-usuario .frame-1838 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 0;
  min-height: 52px;
  width: 335px;
}

.crear-usuario .cliente {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  min-width: 71px;
  text-align: center;
  white-space: nowrap;
}

.crear-usuario .este-usuario-puede-c {
  letter-spacing: 0;
  line-height: 22.5px;
  min-height: 23px;
  text-align: center;
  white-space: nowrap;
  width: 335px;
}

.button-select-user-register-picker {
  align-items: flex-start;
  justify-content: center;
  background-color: var(--e30746);
  border-radius: 34px;
  cursor: pointer;
  display: flex;
  height: 39px;
  margin-top: 11px;
  min-width: 389px;
  padding: 4px 133.0px;
}

.crear-usuario .vector {
  align-self: center;
  height: 15px;
  margin-bottom: 0;
  width: 15px;
}

.crear-usuario .seleccionar {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 12px;
  min-height: 29px;
  white-space: nowrap;
}

.crear-usuario .rectangle-30 {
  background-color: var(--e30746);
  height: 2px;
  margin-left: 1.67px;
  margin-top: 16px;
  width: 389px;
}

.crear-usuario .frame-1841 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 1.51px;
  margin-top: 16px;
  min-height: 125px;
  width: 389px;
}

.crear-usuario .frame-1840 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 74px;
  width: 357px;
}

.crear-usuario .proveedor-de-servicios {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 0;
  margin-top: -1px;
  min-height: 29px;
  min-width: 226px;
  text-align: center;
  white-space: nowrap;
}

.crear-usuario .este-usuario-puede-o {
  letter-spacing: 0;
  line-height: 22.5px;
  min-height: 45px;
  text-align: center;
  width: 357px;
}

.crear-usuario .frame-1836 {
  align-items: flex-start;
  background-color: var(--e30746);
  border-radius: 34px;
  display: flex;
  height: 39px;
  margin-top: 12px;
  min-width: 389px;
  padding: 4px 133.0px;
}

.crear-usuario .frame-1843 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  min-height: 125px;
  width: 392px;
}

.crear-usuario .frame-1842 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 74px;
  width: 392px;
}

.crear-usuario .proveedor-de-terceros {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 0;
  margin-top: -1px;
  min-height: 29px;
  min-width: 221px;
  text-align: center;
  white-space: nowrap;
}

.crear-usuario .este-usuario-puede-o-1 {
  letter-spacing: 0;
  line-height: 22.5px;
  min-height: 45px;
  text-align: center;
  width: 392px;
}

.crear-usuario .frame-1837 {
  align-items: flex-start;
  background-color: var(--e30746);
  border-radius: 34px;
  display: flex;
  height: 39px;
  margin-left: 1.51px;
  margin-top: 12px;
  min-width: 389px;
  padding: 4px 133.0px;
}

.crear-usuario .frame-1848 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 57px;
  position: absolute;
  top: 545px;
  width: 392px;
}

.crear-usuario .rectangle-303 {
  background-color: var(--e30746);
  height: 2px;
  margin-left: 1.71px;
  width: 389px;
}

.crear-usuario .frame-8-1 {
  align-items: flex-start;
  justify-content: center;
  background-color: var(--shark);
  border-radius: 34px;
  cursor: pointer;
  display: flex;
  height: 39px;
  margin-top: 16px;
  min-width: 392px;
  padding: 4px 139.6px;
}

.crear-usuario .group-1835 {
  align-self: center;
  height: 21px;
  width: 26px;
}

.crear-usuario .volver {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 9px;
  min-height: 29px;
  white-space: nowrap;
}

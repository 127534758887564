/* screen - restablecer-contrasena-2 */

.restablecer-contrasena-2 {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  height: 874.77px;
  width: 1440px;
}

.restablecer-contrasena-2 .overlap-group {
  align-items: flex-start;
  background-image: url(https://anima-uploads.s3.amazonaws.com/projects/62b4488c88d82609bf66e125/releases/62babdbc8606f7d7f2443e0a/img/image-35-4@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 875px;
  margin-top: 0;
  min-width: 1440px;
  padding: 124.4px 460.5px;
}

.restablecer-contrasena-2 .frame-13 {
  align-items: center;
  background-color: var(--white);
  border-radius: 28px;
  box-shadow: 0px 2.77px 2.21px #00000005 , 0px 6.65px 5.32px #00000007 , 0px 12.52px 10.02px #00000009 , 0px 22.34px 17.87px #0000000b , 0px 41.78px 33.42px #0000000d , 0px 100px 80px #00000012;
  display: flex;
  flex-direction: column;
  min-height: 324px;
  padding: 44px 0;
  width: 519px;
}

.restablecer-contrasena-2 .frame-9 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 169px;
  width: 445px;
}

.restablecer-contrasena-2 .restablecer-contrasea {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-top: -1px;
  min-height: 29px;
  min-width: 445px;
  white-space: nowrap;
  text-align: center;
}

.restablecer-contrasena-2 .frame-1837 {
  align-items: flex-start;
  background-color: var(--seashell);
  border-radius: 28px;
  display: flex;
  height: 40px;
  margin-top: 31px;
  min-width: 336px;
  padding: 11.5px 30px;
}

.restablecer-contrasena-2 .nueva-contrasea {
  background-color: transparent;
  border: 0;
  height: 15px;
  letter-spacing: 0;
  padding: 0;
  width: 100%;
}

.restablecer-contrasena-2 .nueva-contrasea::placeholder {
  color: #84848499;
}

.restablecer-contrasena-2 .frame-1838 {
  align-items: flex-start;
  background-color: var(--seashell);
  border-radius: 28px;
  display: flex;
  height: 40px;
  margin-top: 30px;
  min-width: 336px;
  padding: 11.5px 30px;
}

.restablecer-contrasena-2 .confirma-la-contrasea {
  background-color: transparent;
  border: 0;
  height: 15px;
  letter-spacing: 0;
  padding: 0;
  width: 100%;
}

.restablecer-contrasena-2 .confirma-la-contrasea::placeholder {
  color: #84848499;
}

.restablecer-contrasena-2 .frame-1831 {
  align-items: flex-start;
  display: flex;
  margin-top: 28px;
  min-width: 336px;
}

.restablecer-contrasena-2 .frame-8 {
  align-items: flex-start;
  background-color: var(--shark);
  border-radius: 34px;
  cursor: pointer;
  display: flex;
  height: 39px;
  min-width: 163px;
  padding: 4px 25.0px;
}

.restablecer-contrasena-2 .group-1835 {
  align-self: center;
  height: 21px;
  width: 26px;
}

.restablecer-contrasena-2 .volver {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 9px;
  min-height: 29px;
  white-space: nowrap;
}

.restablecer-contrasena-2 .frame-8-1 {
  align-items: flex-start;
  background-color: var(--e30746);
  border-radius: 34px;
  display: flex;
  height: 39px;
  justify-content: flex-end;
  margin-left: 40px;
  min-width: 133px;
  padding: 4px 25.0px;
}

.restablecer-contrasena-2 .icon-paper_plane {
  align-self: center;
  height: 19px;
  width: 19px;
}

.restablecer-contrasena-2 .enviar {
  letter-spacing: 0;
  line-height: 28.5px;
  margin-left: 12px;
  min-height: 29px;
  white-space: nowrap;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 9999px;
  background: #fff;
  opacity: 0.5;
  flex-shrink: 0;
}

.swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}

.swiper-pagination {
  display: flex;
  justify-content: flex-start;
  margin-left: 135px;
  position: relative;
  top: -125px;
}

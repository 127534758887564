@import url("https://fonts.googleapis.com/css?family=Montserrat:700");

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}
@media only screen and (max-width: 1100px) {
  .social-media{
    flex-direction: column;
  }
  .social-media-icons{
    margin-top: 2%;
  }
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}
